import { malfunctionTypes } from "utils/constants";
import {
  recommendations, cardStatus, assessmentReleaseStatus, turbineTypes, cardTypes,
} from "./constants";

export const cardStatusTypes = [
  {
    id: 0,
    type: cardStatus.RISK_OF_DAMAGE,
    name: "types.status.riskOfDamage",
    icon: "icon-status_alarm_small",
    iconLarge: "icon-status_alarm",
    class: "alert",
    priority: 2,
  },
  {
    id: 1,
    type: cardStatus.ANOMALY_DETECTED,
    name: "types.status.anomalyDetected",
    icon: "icon-status_warning_small",
    iconLarge: "icon-status_warning",
    class: "warning",
    priority: 1,

  },
  {
    id: 2,
    type: cardStatus.OPERATIONAL,
    name: "types.status.operational",
    icon: "icon-status_ok_small",
    iconLarge: "icon-status_ok",
    class: "normal",
    priority: 0,
  },
];

export function getCardStatusTypeName(type) {
  const item = cardStatusTypes.find((item) => item.type === type);
  if (item) {
    return item.name;
  }
  return "global.notAvailable";
}

export function getCardStatusTypeClass(type) {
  const item = cardStatusTypes.find((item) => item.type === type);
  if (item) {
    return item.class;
  }
  return "default";
}

export function getCardStatusType(type) {
  const item = cardStatusTypes.find((item) => item.type === type);
  if (item) {
    return item;
  }
  return {};
}
export function getCardStatusTypePriority(type) {
  const item = cardStatusTypes.find((item) => item.type === type);
  if (item) {
    return item.priority;
  }
  return -1;
}

export const recommendationTypes = [
  {
    id: 0,
    type: recommendations.NORMAL,
    name: "types.recommendations.normal",
  },
  {
    id: 1,
    type: recommendations.OPTIMISATION,
    name: "types.recommendations.optimisation",
  },
  {
    id: 2,
    type: recommendations.OVERHAUL_PLANING,
    name: "types.recommendations.overhaulPlaning",
  },
  {
    id: 3,
    type: recommendations.SPARE_PARTS_OPITIMISATION,
    name: "types.recommendations.optimisationSpare",
  },
  {
    id: 4,
    type: recommendations.TREND_ANALYSIS,
    name: "types.recommendations.trendAnalysis",
  },
  {
    id: 5,
    type: recommendations.OTHER,
    name: "types.recommendations.other",
  },
];

export function getRecommonendationTypeName(type) {
  const item = recommendationTypes.find(
    (item) => item.type === type,
  );
  if (item) {
    return item.name;
  }
  return "global.notAvailable";
}

export const releaseStatusTypes = [
  {
    id: 0,
    type: assessmentReleaseStatus.DRAFT,
    name: "types.assessmentReleaseStatus.draft",
  },
  {
    id: 1,
    type: assessmentReleaseStatus.LATEST,
    name: "types.assessmentReleaseStatus.latest",
  },
];

export function getReleaseStatusName(status) {
  const item = releaseStatusTypes.find((item) => item.type === status);
  if (item) {
    return item.name;
  }
  return "global.notAvailable";
}

export function getReleaseStatusType(status) {
  const item = releaseStatusTypes.find((item) => item.type === status);
  if (item) {
    return item.type;
  }
  return "";
}

export const cardType = [{
  id: 0,
  type: cardTypes.MALFUNCTION,
  name: "Malfunction",
  icon: "icon-browser_error",
  hasHotspot: true,
  sort: 1,
},
{
  id: 1,
  type: cardTypes.VIBRATION,
  name: "Vibration",
  icon: "icon-measuring_display",
  hasHotspot: false,
  sort: 3,
},
{
  id: 2,
  type: cardTypes.ACOUSTIC,
  name: "OnCare.Acoustic",
  icon: "icon-volume_loud",
  hasHotspot: true,
  sort: 2,
},
{
  id: 3,
  type: cardTypes.ANOMALY,
  name: "Anomaly",
  icon: "icon-KPI",
  hasHotspot: false,
  sort: 4,
},
{
  id: 4,
  type: cardTypes.ADDITIONAL_INFORMATION,
  name: "Additional Information",
  icon: "icon-KPI",
  hasHotspot: false,
  sort: 5,
}];

export function getCardTypeByType(type) {
  const item = cardType.find((item) => item.type === type);
  if (item) {
    return item.name;
  }
  return "global.notAvailable";
}

export function getCardTypeIconByType(type) {
  const item = cardType.find((item) => item.type === type);
  if (item) {
    return item.icon;
  }
  return null;
}

export function getCardTypeIconById(id) {
  const item = cardType.find((item) => item.id === id);
  if (item) {
    return item.icon;
  }
  return null;
}

export function transformMalfunctionTypesToCardType(malfunctionType) {
  if (malfunctionType === malfunctionTypes.HSI) {
    return cardTypes.MALFUNCTION;
  }
  if (malfunctionType === malfunctionTypes.AC) {
    return cardTypes.ACOUSTIC;
  }
  return "";
}

export const turbineType = [{
  id: 0,
  type: turbineTypes.FRANCIS_MIV,
  name: "Francis, with MIV (Default)",
},
{
  id: 1,
  type: turbineTypes.FRANCIS,
  name: "Francis, without MIV",
},
{
  id: 2,
  type: turbineTypes.KAPLAN_HORIZONTAL,
  name: "Kaplan horizontal (Bulb turbine)",
},
{
  id: 3,
  type: turbineTypes.KAPLAN_VERTICAL,
  name: "Kaplan vertical",
},
{
  id: 4,
  type: turbineTypes.PELTON_MIV,
  name: "Pelton, with MIV",
},
{
  id: 5,
  type: turbineTypes.PELTON_HORIZONTAL,
  name: "Pelton horizontal",
},
{
  id: 6,
  type: turbineTypes.SYNCHRONOUS_CONDENSER,
  name: "Synchronous Condenser",
}];

export function getTurbineTypeName(type) {
  const item = turbineType.find((item) => item.type === type);
  if (item) {
    return item.name;
  }
  return "global.notAvailable";
}
