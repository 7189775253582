import React, { forwardRef } from "react";
import MalfunctionsFrancisTurbine from "components/svgs/MalfunctionsFrancisTurbine";
import MalfunctionsFrancisTurbineMiv from "components/svgs/MalfunctionsFrancisTurbineMiv";
import MalfunctionsKaplanHorizontal from "components/svgs/MalfunctionsKaplanHorizontal";
import MalfunctionsKaplanVertical from "components/svgs/MalfunctionsKaplanVertical";
import MalfunctionsPeltonMiv from "components/svgs/MalfunctionsPeltonMiv";
import MalfunctionsPeltonHorizontal from "components/svgs/MalfunctionsPeltonHorizontal";
import MalfunctionsSynchronousCondenser from "components/svgs/MalfunctionsSynchronousCondenser";

import PropTypes from "prop-types";
import { turbineTypes } from "utils/constants";

const SvgGraphic = forwardRef((props, ref) => {
  const { svgPath } = props;
  switch (svgPath) {
    case turbineTypes.FRANCIS: {
      return (
        <MalfunctionsFrancisTurbine {...props} ref={ref} />
      );
    }
    case turbineTypes.FRANCIS_MIV: {
      return (
        <MalfunctionsFrancisTurbineMiv {...props} ref={ref} />
      );
    }
    case turbineTypes.KAPLAN_VERTICAL: {
      return (
        <MalfunctionsKaplanVertical {...props} ref={ref} />
      );
    }
    case turbineTypes.KAPLAN_HORIZONTAL: {
      return (
        <MalfunctionsKaplanHorizontal {...props} ref={ref} />
      );
    }
    case turbineTypes.PELTON_MIV: {
      return (
        <MalfunctionsPeltonMiv {...props} ref={ref} />
      );
    }
    case turbineTypes.PELTON_HORIZONTAL: {
      return (
        <MalfunctionsPeltonHorizontal {...props} ref={ref} />
      );
    }
    case turbineTypes.SYNCHRONOUS_CONDENSER: {
      return (
        <MalfunctionsSynchronousCondenser {...props} ref={ref} />
      );
    }
    default: {
      return (
        <MalfunctionsFrancisTurbine {...props} ref={ref} />
      );
    }
  }
});

SvgGraphic.propTypes = {
  svgPath: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onDrag: PropTypes.func,
  onStop: PropTypes.func,
  malfunctions: PropTypes.arrayOf(PropTypes.object),
  handleMouseOver: PropTypes.func,
  disabled: PropTypes.bool,
};

SvgGraphic.defaultProps = {
  svgPath: "francis",
  width: 1150,
  height: 766,
  onDrag: () => console.log("onDrag"),
  onStop: () => console.log("onStop"),
  malfunctions: [],
  handleMouseOver: () => console.log("handleMouseOver"),
  disabled: true,
};

export default SvgGraphic;
